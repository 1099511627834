export const stories = [
    {
        name: 'Nitin',
        review: '🧙🏽‍♀️ This is great! So many good little features.',
        link: ''
    },
    {
        name: 'Pranav',
        review: 'THIS. I need this in my life. Such a great idea. AND A SOCIAL ASPECT!',
        link: ''
    },
    {
        name: 'Medhansh',
        review: 'Love this concept. I’ll be using it for all my run commands.',
        link: ''
    },
    {
        name: 'Ojas',
        review: 'This is a great idea. So dope.',
        link: ''
    },
]